import { BONIFICATIONS, CHOIX_FORMULE, QUESTION_CHOIX_FORMULE, QUESTION_ELIGIBILITE, QUESTION_FORMULES, QUESTIONS_MPR, VALORISATION_FORMULE } from 'utils/constants';
import { ModeAffichageDisplay, ModeReponseType, QuestionPrime } from '../interfaces/questionnaire';
import { BonificationAPI, Formula, Operande, QuestionApi, ReponseAPI } from '../interfaces/questionnaireAPI';
import { generatedID } from '../utils/functions';

interface MappedQuestionParams {
  key: string;
  question: QuestionApi;
  libelleGroupe: string;
  formuleId?: number;
  isMpr?: boolean;
}

function mapQuestion({ key, question, libelleGroupe, formuleId }: MappedQuestionParams): QuestionPrime {
  const modeReponse = ModeReponseType[question?.mode_reponse] ?? 'column';
  const responseValue = question?.valeur_defaut ? Number(question.valeur_defaut) : '';

  const mapChoicesChoixFormule = (reponse: any) => ({
    id: reponse.formuleId,
    formuleId: reponse.formuleId,
    min: reponse.min,
    max: reponse.max,
    label: reponse.libelle,
    value: String(reponse.valeur),
  });

  const mapChoices = (reponse: ReponseAPI) => ({
    id: reponse.uuid_reponse,
    label: reponse.libelle,
    picto: reponse.pictogramme,
    value: String(reponse.valeur),
    eligible: reponse.eligible,
  });

  return {
    ...(formuleId && { formuleId }),
    parcoursId: key,
    questionId: question?.uuid_question ?? question?.alias ?? '',
    label: question?.libelle,
    labelInput: question?.libelle_saisie_numerique ?? '',
    more: '',
    type: modeReponse,
    alias: modeReponse,
    display: ModeAffichageDisplay[question?.mode_affichage] ?? 'column',
    unit: question?.unite_saisie_numerique ?? null,
    response: question?.valeur ?? responseValue,
    userAnswer: {
      questionId: question?.uuid_question,
      value: responseValue ?? '',
      response: question?.reponses?.map((reponse: ReponseAPI) => String(reponse.uuid_reponse)) ?? [],
    },
    rules: {
      required: true,
      min: question?.min ?? 1,
      max: question?.max,
    },
    why: question?.libelle_info ?? '',
    choices: question?.reponses?.map(mapChoices) ?? [],
    ...(key.includes(CHOIX_FORMULE) && {
      choicesChoixFormule: question?.reponses?.map(mapChoicesChoixFormule) ?? [],
    }),
    group: {
      id: generatedID(),
      label: libelleGroupe,
    },
    isStepProgression: question?.is_etape_progression,
    isTravaux: key.includes(VALORISATION_FORMULE) || key.includes(CHOIX_FORMULE),
    isBonification: key.includes(BONIFICATIONS),
    isMpr: key.includes(QUESTIONS_MPR),
    contraintes: {
      minValue: question?.contraintes?.minValue,
      maxValue: question?.contraintes?.maxValue,
      minLength: question?.contraintes?.minLenght,
      maxLength: question?.contraintes?.maxLenght,
      oneChoiceOnly: question?.mode_reponse === 'choix_unique',
      required: question?.contraintes?.required,
      hasVariableCondition: question?.contraintes?.hasVariableCondition,
    },
    sortie: {},
  };
}

// TODO: remove this function when the backend will be fixed
// case where is no questions in the valorisation formule but the backend need the formule id
function createEmptyQuestion(formula: Formula, libelleGroupe: string): any {
  return {
    formuleId: formula.formuleId,
    parcoursId: VALORISATION_FORMULE,
    group: {
      id: generatedID(),
      label: libelleGroupe,
    },
    emptyQuestion: true,
  };
}

function processFormulas(formulas: Formula[], libelleGroupe: string, output: QuestionPrime[], questionGroup: string, isBonificationFormule: boolean): void {
  formulas.forEach((formula: Formula) => {
    if (formula.questions.length === 0 && !isBonificationFormule) {
      output.push(createEmptyQuestion(formula, libelleGroupe));
    } else {
      const mappedQuestions = formula.questions.map((question: QuestionApi) =>
        mapQuestion({
          key: questionGroup,
          question,
          libelleGroupe,
          formuleId: formula.formuleId,
        })
      );
      output.push(...mappedQuestions);
    }
  });
}

function processBonifications(bonifications: BonificationAPI[], libelleGroupe: string, output: QuestionPrime[]): void {
  bonifications.forEach((bonification: BonificationAPI, indexBonification) => {
    if (bonification.questionsEligibilite.length > 0) {
      const mappedQuestions = bonification.questionsEligibilite.map((question: QuestionApi, indexQuestion) =>
        mapQuestion({
          key: `${BONIFICATIONS}-level${indexBonification + 1}-${QUESTION_ELIGIBILITE}${indexQuestion + 1}`,
          question,
          libelleGroupe,
        })
      );
      output.push(...mappedQuestions);
    }

    if (bonification?.questionChoixFormule !== null) {
      const mappedQuestionChoixFormule = mapQuestion({
        key: `${BONIFICATIONS}-level${indexBonification + 1}-${QUESTION_CHOIX_FORMULE}`,
        question: bonification.questionChoixFormule,
        libelleGroupe,
      });
      output.push(mappedQuestionChoixFormule);
    }

    if (bonification.formules && bonification.formules.length > 0) {
      processFormulas(bonification.formules, libelleGroupe, output, `${BONIFICATIONS}-level${indexBonification + 1}-${QUESTION_FORMULES}`, true);
    }
  });
}

function processChoicesFormule(choicesFormule: any, libelleGroupe: string, output: QuestionPrime[]): void {
  if (choicesFormule?.question !== null) {
    const mappedQuestionChoixFormule = mapQuestion({
      key: CHOIX_FORMULE,
      question: {
        ...choicesFormule.question,
        reponses: choicesFormule.reponses,
      },
      libelleGroupe,
    });
    output.push(mappedQuestionChoixFormule);
  }
}

export function mapAPITravauxQuestion(data: Operande): QuestionPrime[] {
  const output: QuestionPrime[] = [];

  if (data[CHOIX_FORMULE]) {
    processChoicesFormule(data[CHOIX_FORMULE], data.libelle_groupe, output);
  }

  if (data[BONIFICATIONS]) {
    processBonifications(data[BONIFICATIONS], data.libelle_groupe, output);
  }

  if (data[QUESTIONS_MPR]) {
    data[QUESTIONS_MPR].forEach((question: QuestionApi) => {
      output.push(
        mapQuestion({
          key: QUESTIONS_MPR,
          question,
          libelleGroupe: data.libelle_groupe,
        })
      );
    });
  }

  if (data[VALORISATION_FORMULE]) {
    processFormulas(data[VALORISATION_FORMULE], data.libelle_groupe, output, VALORISATION_FORMULE, false);
  }

  return output;
}
