// Thoses constants are used to identify the questions in the API response

// Quelle est l'adresse du logement concerné par les travaux ?
export const ADRESS_QUESTION_ID = '3b73d096-8430-40c2-8b08-3fa5874db7bc';
// Combien de personnes vivent dans le logement concerné par les travaux ?
export const PERSON_QUESTION_ID = '92aa06ad-c248-4659-b765-ab99bca2640f';

// Les travaux concernent : (Maison , Appartement)
export const TYPE_QUESTION_ID = '843c6126-628d-11ee-8c99-0242ac120002';

// Quelle est la somme des revenus fiscaux de votre foyer ?
export const REVENUE_QUESTION_ID = '7a7f6539-9b3a-4a9a-b03a-3461c7e0d875';

// Quel type de travaux d'économie d'énergie souhaitez-vous faire ?
export const WORK_QUESTION_LABEL = "Quel type de travaux d'économie d'énergie souhaitez-vous faire ?";

export const ELIGIBILTY = 'eligibilite';
export const CHOIX_FORMULE = 'choixFormule';
export const VALORISATION_FORMULE = 'valorisationFormule';
export const BONIFICATIONS = 'bonifications';
export const QUESTION_ELIGIBILITE = 'questionsEligibilite';
export const QUESTION_CHOIX_FORMULE = 'questionChoixFormule';
export const QUESTION_FORMULES = 'formules';
export const QUESTIONS_MPR = 'questions_mpr';

export const PHONE_NUMBER = '04 28 70 18 56';
export const SERVICE_EMAIL = 'serviceclient@sonergia.fr';

// Motif abandon "Autres"
export const MOTIF_OTHER_ID = '0c58077c-f068-4c22-82a5-8775598aba21';
