import { api, Resource } from 'core';

export class MarcheBlancheApi extends Resource {
  constructor(entrypoint = '/primes') {
    super(entrypoint);
  }

  getLogo(alias: string): Promise<{
    logo: string;
  }> {
    return api
      .get(this.buildUri(`marqueblanche/${alias}/logo`))
      .then(({ data }) => data)
      .catch(() => '');
  }
}

export default new MarcheBlancheApi();
