import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useSimulationResultat from 'features/primes/simulation/resultat/hooks/useSimulationResultat';
import './results.scss';
import Confetti from 'react-dom-confetti';

import security from '@concerto-home/security';
import { confettiConfig } from 'features/primes/simulation/resultat/helpers/config';
import ProjectInfoItem from 'features/primes/simulation/resultat/components/resultat/ProjectInfoItem';
import NotFound from 'pages/not-found';

import { ProjectTitle } from 'features/primes/simulation/resultat/helpers/constants';
import { trackSimulationResultBtnClick, trackSimulationViewResult } from 'data-layer';
import { LoaderSimple } from '@concerto-home/core';

import lang from '../lang/resultat.json';
import { EstimatifTravaux } from '../components/resultat/EstimatifTravaux';
import PrimeEnergie from '../components/resultat/PrimeEnergie';

const Results = () => {
  const { simulationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isConfetti, setIsConfetti] = useState(false);
  const { simulation = null, isLoading = false } = security.user ? useSimulationResultat(simulationId || '') : {};
  const hasAat = simulation?.hasAat || false;
  const { montants, travaux, profile, logement, isNonCumulable, actions } = simulation || {};
  const { isRemplassableCarRevision, isRemplassableCarDesactivation } = actions || {};
  const isRemplassable = isRemplassableCarDesactivation || isRemplassableCarRevision;

  // Track view result event
  useEffect(() => {
    if (!security.user) return;
    if (simulation && !isLoading) {
      trackSimulationViewResult({
        housing: logement?.typeLogement || '',
        exactWork: travaux?.operation || '',
        amount: montants?.montantPrimeEnergie?.toString() || '',
      });
    }
  }, [simulation, isLoading]);

  useEffect(() => {
    if (!security.user) {
      navigate('/login', {
        state: { from: location.pathname },
      });
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setIsConfetti(true);
    }
  }, [isLoading]);

  const handleDemandePrime = () => {
    // Target tracking for click event on button
    trackSimulationResultBtnClick({
      buttonId: 'demande-prime',
      buttonName: lang.actions.requestPrime,
      housing: logement?.typeLogement || '',
      exactWork: travaux?.operation || '',
      amount: montants?.montantPrimeEnergie?.toString() || '',
    });

    if (hasAat) {
      navigate(`/dashboard/constitution-dossier/${simulationId}`);
      return;
    }

    // Redirect to AAT page
    navigate(`/simulation-prime/informations-AAT/${simulationId}`);
  };

  const goToDetail = () => {
    navigate(`/dashboard/${simulationId}`);
    // Target tracking for click event on button
    trackSimulationResultBtnClick({
      buttonId: 'detail-prime',
      buttonName: lang.actions.details,
      housing: logement?.typeLogement || '',
      exactWork: travaux?.operation || '',
      amount: montants?.montantPrimeEnergie?.toString() || '',
    });
  };

  if (isLoading) {
    return <LoaderSimple />;
  }

  if (!simulation) {
    return <NotFound />;
  }

  const isEstimatifTravauxAvailable = montants?.montantEstimatifTravaux !== null;

  const className = isLoading ? 'result--loading' : 'result';
  return (
    <div className={className}>
      <Confetti active={isConfetti} config={confettiConfig} />
      <div className="result__presentation">
        <h1 className="result__presentation-title">{isEstimatifTravauxAvailable ? lang.presentation.titleEstimatifTravaux : lang.presentation.title}</h1>
        <p className="result__presentation-description">{isEstimatifTravauxAvailable ? lang.presentation.descriptionEstimatifTravaux : lang.presentation.descriptionPrimeEnergie}</p>
      </div>

      <div className="result__prime-wrapper">{isEstimatifTravauxAvailable ? <EstimatifTravaux montants={montants} isNonCumulable={isNonCumulable} handleDemandePrime={handleDemandePrime} isRemplassable={isRemplassable} goToDetail={goToDetail} /> : <PrimeEnergie montantPrimeEnergie={montants?.montantPrimeEnergie || 0} isNonCumulable={isNonCumulable} handleDemandePrime={handleDemandePrime} isRemplassable={isRemplassable} goToDetail={goToDetail} />}</div>

      <div className="result__mention">
        <p className="result__mention-description">{lang.mention}</p>
      </div>

      <div className="result__project">
        <div className="result__project-title">
          <h3>{lang.project.title}</h3>
        </div>

        <div className="result__project-content">
          <ProjectInfoItem title={ProjectTitle.VosTravaux}>
            <li>
              {travaux?.operation}
              {travaux?.libelleComplementaire && ` - ${travaux?.libelleComplementaire} `}
            </li>
          </ProjectInfoItem>
          <ProjectInfoItem title={ProjectTitle.VotreProfil}>
            <li>{profile?.status}</li>
            <li>
              {profile?.numberOfPeople} {lang.project.persons} {profile?.revenus && `(Revenu : ${profile?.revenus})`}
            </li>
          </ProjectInfoItem>
          <ProjectInfoItem title={ProjectTitle.VotreLogement}>
            <li>{`${logement?.typeLogement}, ${logement?.address.postalCode} ${logement?.address.city}`}</li>
            <li>{lang.project.moreThanTwoYears}</li>
          </ProjectInfoItem>
        </div>
      </div>
    </div>
  );
};

export default Results;
