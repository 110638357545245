// DetailSquare.tsx
import React from 'react';

interface DetailSquareProps {
  colorClass: string;
}

const DetailSquare: React.FC<DetailSquareProps> = ({ colorClass }) => <span className={`result-prime__detail-square ${colorClass}`} />;

export default DetailSquare;
