// DetailActions.tsx
import React from 'react';
import Button from 'features/dashboard/components/button/button';
import { NonCumulable } from './NonCumulable';
import lang from '../../lang/resultat.json';

interface DetailActionsProps {
  isNonCumulable?: boolean;
  handleDemandePrime?: () => void;
  isRemplassable?: boolean;
  goToDetail?: () => void;
}

const DetailActions: React.FC<DetailActionsProps> = ({ isNonCumulable, handleDemandePrime, isRemplassable, goToDetail }) => (
  <div className="result-prime__detail-actions">
    {isNonCumulable ? <NonCumulable /> : <Button label={lang.actions.requestPrime} type="button" id="demande-prime" variant="primary" onClick={handleDemandePrime} isDisabled={isNonCumulable || isRemplassable} />}
    <Button label={lang.actions.details} type="button" id="detail-prime" variant="tertiary" size="small" classNames="m-4" onClick={goToDetail} />
  </div>
);

export default DetailActions;
