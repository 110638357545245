import React, { FC } from 'react';
import './realisationTravaux.scss';
import { MdErrorOutline } from 'react-icons/md';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { useParams } from 'react-router';
import NotFound from 'pages/not-found';
import useSimulationResultat from 'features/primes/simulation/resultat/hooks/useSimulationResultat';
import useDocuments from 'features/dashboard/hooks/useDocuments';
import { LoaderSimple } from '@concerto-home/core';
import InformationTravauxAPI from 'features/dashboard/services/informationTravauxAPI';
import { formatDocumentsTitle, handleDuplicateTitles, SIMULATION_STATUS } from 'features/dashboard/helpers/utils';
import { trackClickFinishPerStep } from 'data-layer';
import { useRecoilValue } from 'recoil';
import { countInProgressSimulationsSelector } from 'features/dashboard/hooks/dashboard';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import Typography from '../typography/typography';
import Button from '../button/button';
import DocumentItem from './documentItem';
import PrequisItem from './prequisItem';
import lang from '../../lang/projets.json';

interface RealisationTravauxProps {
  refresh: () => void;
}

const RealisationTravaux: FC<RealisationTravauxProps> = ({ refresh }) => {
  const { simulationId } = useParams();
  const { realisation } = lang || {};

  if (!simulationId) {
    return <NotFound />;
  }
  const { simulation, isLoading } = useSimulationResultat(simulationId);
  const { documents } = useDocuments({ simulationId });

  const formattedDocuments = formatDocumentsTitle(documents || []);

  const uniquesDocuments = handleDuplicateTitles(formattedDocuments);

  const uploadedDocuments = uniquesDocuments?.filter((doc) => doc.idFile !== null) || [];
  const notUploadedDocuments = uniquesDocuments?.filter((doc) => doc.idFile === null) || [];

  const { montants, statuts } = simulation || {};

  const isDossierFinalized = statuts?.find((statut) => statut.ordre === 3)?.completion === SIMULATION_STATUS.CREATED;

  const prequisItems = [{ title: realisation.prequisItems.item1 }, { title: realisation.prequisItems.item2 }, { title: realisation.prequisItems.item3 }];

  // used in tracking analytics
  const countInProgressSimulations = useRecoilValue(countInProgressSimulationsSelector);

  const handleRealisationTravaux = async () => {
    const response = await InformationTravauxAPI.postToFinalisationDossier(simulationId);
    if (response.success) {
      refresh();
    }
    window.scrollTo(0, 0);

    // Target Tracking - Finish a Step
    trackClickFinishPerStep(simulation as Resultat, countInProgressSimulations, 'Réalisation de vos travaux');
  };

  if (isLoading) {
    return <LoaderSimple />;
  }

  return (
    <div className="realisation-travaux">
      <div className="realisation-travaux__container">
        <Typography variant="h2" size="xl" fontWeight="bold" customClass="realisation-travaux__title">
          {realisation.title}
        </Typography>
        <div className="realisation-travaux__subtitle">
          <div className="realisation-travaux__subtitle__icon">
            <MdErrorOutline size={25} color="#AD0E0E" />
          </div>
          <div className="realisation-travaux__subtitle__text"> {realisation.subtitle}</div>
        </div>
        <div className="realisation-travaux__content">
          {prequisItems.map((item) => (
            <PrequisItem key={item.title} title={item.title} />
          ))}

          <div className="realisation-travaux__content__prime">
            <div className="realisation-travaux__content__prime__price">{montants?.montantPrimeEnergie} €</div>
            <div className="realisation-travaux__content__prime__title"> {realisation.prime}</div>
          </div>
          <div className="realisation-travaux__content__prime__disclaimer"> {realisation.disclaimer}</div>
          <div className="separator" />

          <div className="realisation-travaux__content__documents">
            <div className="realisation-travaux__content__documents__title"> {realisation.requiredDocument}</div>
            <div className="realisation-travaux__content__documents__items">
              <div className="realisation-travaux__content__documents__items__uploaded">
                {uploadedDocuments.map((item) => (
                  <DocumentItem key={item.title} title={item.title} renderIcon={<FaCheckCircle size={20} color="#23C275" />} />
                ))}
              </div>
              <div className="realisation-travaux__content__documents__items_not-uploaded">
                {notUploadedDocuments.map((item) => (
                  <DocumentItem key={item.title} title={item.title} renderIcon={<FaExclamationCircle size={20} color="DB7927" />} />
                ))}
              </div>
            </div>
          </div>

          <div className="realisation-travaux__actions">
            <Button id="finalized-dossier" type="button" isDisabled={isDossierFinalized} variant="primary" label="J’ai terminé mes travaux" onClick={handleRealisationTravaux} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealisationTravaux;
