import { useLocation } from 'react-router-dom';
import { useEffect, useCallback } from 'react';

const usePartenaire = () => {
  const location = useLocation();

  const getQueryParam = useCallback(
    (param: string) => {
      const searchParams = new URLSearchParams(location.search);
      return searchParams.get(param);
    },
    [location.search]
  );

  useEffect(() => {
    const partenaire = getQueryParam('partenaire');

    if (partenaire) {
      localStorage.setItem('partenaire', partenaire);
    } else {
      localStorage.removeItem('partenaire');
    }
  }, [getQueryParam]);

  const partenaire = localStorage.getItem('partenaire');

  return partenaire;
};

export default usePartenaire;
