import React from 'react';
import DetailItem from './DetailItem';
import lang from '../../lang/resultat.json';
import { Montant } from '../../interfaces/resultat';
import { calculatePercentage } from '../../helpers/functions';

interface EstimatifTravauxProps {
  montants: Montant | undefined;
  isNonCumulable: boolean | undefined;
  handleDemandePrime: () => void;
  isRemplassable: boolean | undefined;
  goToDetail: () => void;
}

export const EstimatifTravaux: React.FC<EstimatifTravauxProps> = ({ montants, isNonCumulable, handleDemandePrime, isRemplassable, goToDetail }) => {
  const { montantPrimeEnergie = 0, montantMpr = 0, resteACharge = 0, montantEstimatifTravaux = 1 } = montants || {};

  // Calculate widths for each part of the progress bar
  const primeWidth = calculatePercentage(montantPrimeEnergie, montantEstimatifTravaux);
  const mprWidth = calculatePercentage(montantMpr, montantEstimatifTravaux);
  const resteWidth = calculatePercentage(resteACharge, montantEstimatifTravaux);

  return (
    <section className="result-prime">
      <header className="result-prime__header">
        <h2 className="result-prime__title">{lang.cout.title}</h2>
        <h2 className="result-prime__estimate">{montantEstimatifTravaux} €</h2>
      </header>

      <div className="result-prime__progression">
        <div className="result-prime__progression-prime" style={{ width: `${primeWidth}%` }} />
        <div className="result-prime__progression-mpr" style={{ width: `${mprWidth}%` }} />
        <div className="result-prime__progression-reste" style={{ width: `${resteWidth}%` }} />
      </div>

      <article className="result-prime__details">
        <h3 className="result-prime__subtitle">{lang.cout.subtitle}</h3>
        <DetailItem amount={montantPrimeEnergie} description="Prime énergie Sonergia" additionalInfo={lang.rappelPrimeEnergie} colorClass="orange info-icon" isNonCumulable={isNonCumulable} handleDemandePrime={handleDemandePrime} isRemplassable={isRemplassable} goToDetail={goToDetail} withAction />
        {montantMpr ? (
          <DetailItem
            amount={montantMpr}
            description="MaPrimeRénov'"
            additionalInfo={
              <>
                {lang.aideMaPrimeRenov}
                <a href="https://sonergia.fr/guide-des-aides/autres-aides/le-guide-de-maprimerenov-2024/" target="_blank" rel="noopener noreferrer">
                  article
                </a>
                {lang.aideMaPrimeRenov2}
              </>
            }
            colorClass="green question-icon"
          />
        ) : null}
        <DetailItem amount={resteACharge} description="Montant restant à votre charge" colorClass="dark  " />
      </article>
    </section>
  );
};
