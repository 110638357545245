import React from 'react';

import Button from 'features/dashboard/components/button/button';
import lang from '../../lang/resultat.json';
import { NonCumulable } from './NonCumulable';

interface PrimeEnergieProps {
  montantPrimeEnergie: number;
  isNonCumulable: boolean | undefined;
  handleDemandePrime: () => void;
  isRemplassable: boolean | undefined;
  goToDetail: () => void;
}

const PrimeEnergie: React.FC<PrimeEnergieProps> = ({ montantPrimeEnergie, isNonCumulable, handleDemandePrime, isRemplassable, goToDetail }) => (
  <>
    <div className="result__prime-amount">
      <div className="result__prime-amount-value">
        <h2>{montantPrimeEnergie} €</h2>
      </div>
      <div className="result__prime-amount-text">
        <p className="result__prime-amount-text-title">{lang.amount.title}</p>
        <p className="result__prime-amount-text-description">{lang.amount.description}</p>
      </div>
    </div>

    <div className="result__prime-amount-actions">
      {isNonCumulable ? <NonCumulable /> : <Button label={lang.actions.requestPrime} type="button" id="demande-prime" variant="primary" classNames="m-4" onClick={handleDemandePrime} isDisabled={isNonCumulable || isRemplassable} />}
      <Button label={lang.actions.details} type="button" id="detail-prime" variant="tertiary" classNames="m-4" onClick={goToDetail} />
    </div>
  </>
);

export default PrimeEnergie;
