import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import { ResultatAPI } from 'features/primes/simulation/resultat/interfaces/resultatAPI';
import { convertDate } from '../helpers/utils';

export function mapAPISimulations(simulations: ResultatAPI[]): Resultat[] {
  return simulations.map((simulation) => ({
    id: simulation.id,
    uuid: simulation.uuid,
    montants: {
      montantEstimatifTravaux: simulation.montants?.montantEstimatifTravaux,
      montantPrimeEnergie: simulation.montants?.montantPrimeEnergie,
      montantMpr: simulation.montants?.montantMpr,
      resteACharge: simulation.montants?.resteACharge,
    },
    dateCreationSimulation: simulation.dateCreationSimulation,
    dateExpirationDossier: simulation.dateExpirationDossier,
    travaux: {
      operation: simulation.travaux.operation,
      dateDevis: simulation.travaux.dateDevis,
      picto: simulation.travaux.pictogramme,
      libelleComplementaire: simulation.travaux.libelleComplementaire,
      codeTravaux: simulation.travaux.codeTravaux,
    },
    logement: {
      typeLogement: simulation.logement.typeLogement,
      address: {
        street: simulation.logement.adresse.rue,
        complement: simulation.logement.adresse.complement,
        postalCode: simulation.logement.adresse.code_postal,
        city: simulation.logement.adresse.ville,
      },
    },
    isNonCumulable: simulation.isNonCumulable,
    statuts: simulation.statuts,
    hasAat: simulation.hasAat,
    hasAh: simulation.hasAh,
    actions: {
      isDeletable: simulation?.actions?.estSupprimable,
      isAbandonable: simulation?.actions?.estAbandonnable,
      isRemplassableCarRevision: simulation?.actions?.estRemplacableCarRevision,
      isRemplassableCarDesactivation: simulation?.actions?.estRemplacableCarDesactivation,
    },
    isDossierExpire: simulation?.dateExpirationDossier ? convertDate(simulation?.dateExpirationDossier) < new Date() : false,
    isAdressNotComplete: (simulation.logement?.adresse?.rue?.toLowerCase() || '') === (simulation.logement?.adresse?.ville?.toLowerCase() || ''),
  }));
}
