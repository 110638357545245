// Helper function to calculate percentage
export const calculatePercentage = (value: number, total: number | null) => {
  if (!value || !total) return 0;
  return (value / total) * 100;
};

// Utility functions to handle localStorage updates
export const saveToLocalStorage = (key: string, data: unknown) => {
  localStorage.setItem(key, JSON.stringify(data));
};
