// DetailInfo.tsx
import React from 'react';

interface DetailInfoProps {
  amount: number;
  description: string;
  colorClass: string;
}

const DetailInfo: React.FC<DetailInfoProps> = ({ amount, description, colorClass }) => (
  <div className={`result-prime__detail-info ${colorClass}`}>
    <p className="result-prime__detail-info-amount">{amount} €</p>
    <p className="result-prime__detail-info-description">{description}</p>
  </div>
);

export default DetailInfo;
