import React from 'react';
import logementSvg from 'features/primes/simulation/resultat/assets/images/logement.svg';
import profileSvg from 'features/primes/simulation/resultat/assets/images/profile.svg';
import travauxSvg from 'features/primes/simulation/resultat/assets/images/travaux.svg';
import { ProjectTitle } from '../../helpers/constants';

// Create a mapping between titles and images
const projectInfoImages: Record<ProjectTitle, string> = {
  [ProjectTitle.VosTravaux]: travauxSvg,
  [ProjectTitle.VotreProfil]: profileSvg,
  [ProjectTitle.VotreLogement]: logementSvg,
};

const ProjectInfoItem = ({ title, children }: { title: ProjectTitle; children: React.ReactNode }) => (
  <div className="result__project-info">
    <div className="result__project-info-picto">
      <img src={projectInfoImages[title]} alt="ico_project" />
    </div>
    <div className="result__project-info-title">
      <h4>{title}</h4>
    </div>
    <div className="result__project-info-value">{children}</div>
  </div>
);

export default ProjectInfoItem;
