// DetailItem.tsx
import React from 'react';
import DetailSquare from './DetailSquare';
import DetailInfo from './DetailInfo';
import DetailActions from './DetailActions';

interface DetailItemProps {
  amount: number;
  description: string;
  additionalInfo?: React.ReactNode;
  colorClass: string;
  isNonCumulable?: boolean;
  handleDemandePrime?: () => void;
  isRemplassable?: boolean;
  goToDetail?: () => void;
  withAction?: boolean;
}

const DetailItem: React.FC<DetailItemProps> = ({ amount, description, additionalInfo, colorClass, isNonCumulable, handleDemandePrime, isRemplassable, goToDetail, withAction }) => (
  <div className="result-prime__detail">
    <DetailSquare colorClass={colorClass} />
    <div className="result-prime__detail-content">
      <DetailInfo amount={amount} description={description} colorClass={colorClass} />
      {additionalInfo && <p className={`result-prime__detail-content-additional-info ${colorClass}`}>{additionalInfo}</p>}
    </div>
    {withAction && <DetailActions isNonCumulable={isNonCumulable} handleDemandePrime={handleDemandePrime} isRemplassable={isRemplassable} goToDetail={goToDetail} />}
  </div>
);

export default DetailItem;
