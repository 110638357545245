import React, { useEffect, useState } from 'react';
import { useLocation, matchPath, NavLink } from 'react-router-dom';
import { useSharedState } from 'states';
import logo from 'assets/images/logo.svg';
import AccountMenu from 'components/account/menu';
import AccountLogin from 'components/account/login';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { LuUser2 } from 'react-icons/lu';
import { Accordion } from 'react-bootstrap';
import SubNavigationItem from 'features/dashboard/components/navigation/subNavigationItem';
import { useRecoilValue } from 'recoil';
import { inProgressSimulationsLoadingState, inProgressSimulationsState } from 'features/dashboard/states/simulations';
import { useSimulations } from 'features/dashboard/hooks/dashboard';
import { SIMULATION } from 'features/dashboard/helpers/utils';
import MarcheBlancheApi from 'features/primes/simulation/questionnaire/services/marcheBlancheApi';
import SharedMenu from './menu';

const Header: React.FC = () => {
  const location = useLocation();
  const [showMenuUser, setShowMenuUser] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const [user] = useSharedState('security:user');
  const [{ show }, setLoginContext] = useSharedState('security:login');
  const isLoginPage = location.pathname === '/login';
  const isAnalysEnergetique = location.pathname === '/analyse-energetique/creer';
  const [urlToRedirect, setUrlToRedirect] = useState(isAnalysEnergetique ? '/analyse-energetique/creer' : '/simulation-prime');
  const [logoPartenaireImg, setLogoPartenaireImg] = useState('');

  const { search } = location;

  const queryParam = new URLSearchParams(search);

  // call prime api to get logo from marque blanche
  useEffect(() => {
    if (queryParam.get('partenaire') && queryParam.get('partenaire') !== 'null') {
      MarcheBlancheApi.getLogo(queryParam.get('partenaire') as string).then((logoPartenaire) => {
        setLogoPartenaireImg(logoPartenaire.logo);
      });
    }
  }, [queryParam]);

  const isMobile = window.innerWidth < 992;
  useSimulations(SIMULATION.IN_PROGRESS, !!user && isMobile);

  const simulations = useRecoilValue(inProgressSimulationsState);
  const isLoading = useRecoilValue(inProgressSimulationsLoadingState);

  const updateShowLogin = () => setLoginContext({ show: !show });

  const isDiagnosticResult = matchPath('/analyse-energetique/:id/diagnostic', location.pathname);

  useEffect(() => {
    document.body.classList.toggle('no-scroll', showMenu || showMenuUser);
  }, [showMenu, showMenuUser]);

  useEffect(() => {
    document.body.classList.toggle('user-connected', !!user);
    document.body.classList.toggle('user-not-connected', !user);

    if (user) {
      setUrlToRedirect('/dashboard');
    }
  }, [user]);

  if (isDiagnosticResult && !user) {
    return null;
  }

  return (
    <>
      <header className="header">
        {user && (
          <>
            <button type="button" className="headerMenu" onClick={() => setShowMenu(!showMenu)}>
              <i aria-label="Menu" className={showMenu ? 'icon-close' : 'icon-menu'} />
            </button>
            <div className={`headerNavBurger ${open ? 'open' : ''}`} onClick={() => setOpen(!open)}>
              <span />
              <span />
              <span />
              <span />
            </div>
          </>
        )}
        <h1 className="headerLogo" role="banner">
          <a href={urlToRedirect} rel="home">
            <img src={logoPartenaireImg || logo} alt="Sonergia" height="50" />
          </a>
        </h1>
        <nav id="nav-main" className={`headerNav ${open ? 'block' : ''}`} role="navigation">
          <SharedMenu />
          <ul className="headerNavLeft">
            <li className="headerNavLeftItem hideOnMobile ">Vos économies d&apos;énergie en plus simple</li>
            <div className="forMobile">
              <li className="headerNavLeftItem">
                <NavLink to="/dashboard" className="headerNavLeftItemLink" onClick={() => setOpen(false)}>
                  Mon tableau de bord
                </NavLink>
              </li>
              <div className="accountInfosMenu">
                <Accordion>
                  <Accordion.Item eventKey="Mes demandes en cours">
                    <Accordion.Header>Mes demandes en cours</Accordion.Header>
                    <Accordion.Body>
                      {simulations.map((simulation) => (
                        <SubNavigationItem key={simulation.id} simulation={simulation} isLoading={isLoading} />
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </ul>
          <ul className="headerNavRight" />
        </nav>
        {user ? (
          <div className="headerNavRightItem headerNavRightItemAccount">
            <button type="button" className="headerNavRightItemLink" data-cy="user-dropdown" onClick={() => setShowMenuUser(!showMenuUser)} onKeyDown={() => setShowMenuUser(!showMenuUser)}>
              <LuUser2 size={22} className="m-2" />
              <strong>
                {user.firstName}&nbsp;{user.lastName[0].toUpperCase()}.
              </strong>
              {showMenuUser ? <FaChevronUp /> : <FaChevronDown />}
            </button>
          </div>
        ) : (
          !isLoginPage && (
            <>
              <div className="headerNavRightItem headerNavRightItemAccount">
                <button type="button" className="headerNavRightItemLink" onClick={updateShowLogin}>
                  <LuUser2 size={22} className="m-2" />
                  <span>Connexion</span>
                </button>
              </div>
              <AccountLogin />
            </>
          )
        )}
        {showMenuUser && <AccountMenu handleClose={() => setShowMenuUser(false)} />}
      </header>
    </>
  );
};

export default Header;
