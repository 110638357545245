import React from 'react';
import './demandeDetails.scss';
import Header from 'features/dashboard/components/header/header';
import List from 'features/dashboard/components/simulations/list/list';
import useSimulationResultat from 'features/primes/simulation/resultat/hooks/useSimulationResultat';
import { useNavigate, useParams } from 'react-router';
import { ListLoader } from 'features/dashboard/components/contentLoader/list.skeleton';
import NotFound from 'pages/not-found';
import Breadcrumbs from 'features/dashboard/components/breadcrumbs';
import { trackClickDemandPrime } from 'data-layer';
import lang from 'features/primes/simulation/resultat/lang/resultat.json';

interface EstimatifTravauxProps {
  montantEstimatifTravaux: number;
}

const EstimatifTravauxComponent: React.FC<EstimatifTravauxProps> = ({ montantEstimatifTravaux }) => (
  <div className="demande__details__content__estimatif">
    <div className="demande__details__content__estimatif__description">
      <div className="demande__details__content__estimatif__description__title">
        {lang.aideMaPrimeRenov}
        <a href="https://sonergia.fr/guide-des-aides/autres-aides/le-guide-de-maprimerenov-2024/" target="_blank" rel="noopener noreferrer">
          article
        </a>
        {lang.aideMaPrimeRenov2}
      </div>
    </div>
    <div className="demande__details__content__estimatif__prime">
      <div className="demande__details__content__estimatif__prime__text">
        <p className="demande__details__content__estimatif__prime__text__title">MaPrimeRenov’</p>
      </div>
      <div className="demande__details__content__estimatif__prime__value">
        <h2>{montantEstimatifTravaux} €</h2>
      </div>
    </div>
  </div>
);

const DemandeDetails: React.FC = () => {
  const { simulationId } = useParams();
  const navigate = useNavigate();

  if (!simulationId) {
    return null;
  }

  const { simulation, isLoading } = useSimulationResultat(simulationId);
  const { actions, hasAat, montants } = simulation || {};
  const { isRemplassableCarRevision, isRemplassableCarDesactivation } = actions || {};
  const isRemplassable = isRemplassableCarDesactivation || isRemplassableCarRevision;
  const isEstimatifTravauxAvailable = montants?.montantEstimatifTravaux !== null;

  if (isLoading) {
    return <ListLoader />;
  }

  if (!simulation) {
    return <NotFound />;
  }

  const handleDemandePrime = () => {
    // Target tracking event for demandes
    trackClickDemandPrime({
      buttonId: 'request-prime-detail',
      buttonName: 'Demander cette prime',
      housing: simulation.logement?.typeLogement,
      exactWork: simulation.travaux?.operation,
      amount: simulation.montants.montantPrimeEnergie?.toString(),
    });

    if (isRemplassable) {
      navigate('/simulation-prime', {
        state: { simulationId, update: true },
      });
      return;
    }

    if (hasAat) {
      navigate(`/dashboard/constitution-dossier/${simulationId}`);
      return;
    }

    navigate(`/simulation-prime/informations-AAT/${simulationId}`);
  };

  return (
    <div className="demande__details">
      <Header />
      <Breadcrumbs onBack={() => navigate('/dashboard')} />
      <div className="demande__details__content">
        <h2 className="demande__details__content__title">{simulation.travaux.operation}</h2>
        <List simulation={simulation} isDetail onClick={handleDemandePrime} isEstimatifTravauxAvailable={isEstimatifTravauxAvailable} />
        {isEstimatifTravauxAvailable && montants?.montantMpr ? <EstimatifTravauxComponent montantEstimatifTravaux={montants?.montantMpr || 0} /> : null}
      </div>
    </div>
  );
};

export default DemandeDetails;
