import React from 'react';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';

import { labelButton } from 'features/dashboard/helpers/utils';
import Actions from './actions';
import PrimeActionButton from './primeActionButton';
import PrimeContentBox from './primeUpdate';

interface PrimeDetailsProps {
  simulation: Resultat;
  isClosed?: boolean;
  isDetail?: boolean;
  onClick: () => void;
}

const PrimeDetails: React.FC<PrimeDetailsProps> = ({ simulation, isClosed, isDetail, onClick }) => {
  const { actions, isNonCumulable, montants, isDossierExpire } = simulation || {};
  const { isRemplassableCarRevision, isRemplassableCarDesactivation } = actions || {};
  const label = labelButton(simulation, isDetail, isClosed, isDossierExpire);

  return (
    <div className="dashboard__demandes__list__item__prime">
      <PrimeContentBox isRemplassableCarRevision={isRemplassableCarRevision} isRemplassableCarDesactivation={isRemplassableCarDesactivation} montants={montants} isNonCumulable={isNonCumulable} isClosed={isClosed} isDetail={isDetail} isDossierExpire={isDossierExpire} />
      <div className="dashboard__demandes__list__item__prime__actions">
        {!isDossierExpire && !isRemplassableCarDesactivation && <PrimeActionButton label={label} isDisabled={!!(isNonCumulable || isClosed)} isRemplassable={isRemplassableCarRevision || isRemplassableCarDesactivation} isNonCumulable={isNonCumulable} onClick={onClick} />}
        <Actions simulation={simulation} />
      </div>
    </div>
  );
};

export default PrimeDetails;
