import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import Tooltip from 'components/tooltip/Tooltip';
import lang from '../../lang/resultat.json';

export const NonCumulable = () => (
  <div className="result__non-cumulable">
    <Tooltip text={lang.nonCumulTooltip} showTooltip placement="top">
      <div className="result__non-cumulable-title">
        <h3>{lang.nonCumulTitle}</h3>
        <div className="result__non-cumulable-title-icon">
          <IoIosInformationCircleOutline size={20} color="#A7A7A7" />
        </div>
      </div>
    </Tooltip>

    <div className="result__non-cumulable-description">{lang.nonCumulText}</div>
  </div>
);
