import pageTypesData from 'page-types.json';
import { questionResource } from 'core';
import security from '@concerto-home/security';

import type { Question, SurveyResponses, ContactForm, Customer, Survey } from 'core';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';

const win = window as any; // eslint-disable-line

win.dataLayer = win.dataLayer || [];

const { dataLayer } = win;

export function handleCurrentPageLoaded(pathname?: string, customer?: Customer): void {
  const urls = Object.keys(pageTypesData);

  let currentPageType;

  const currentPath = pathname || window.location.pathname;
  const currentPathParts = currentPath.split('/').filter((part) => part !== '');

  for (let i = 0, len = urls.length; i < len; i += 1) {
    const pathParts = urls[i].split('/').filter((p) => p !== '');

    let isValid = true;

    // Both url must have same path length
    if (pathParts.length !== currentPathParts.length) {
      continue; // eslint-disable-line
    }

    // Check every url part, skip parameters
    for (let z = 0, zlen = pathParts.length; z < zlen; z += 1) {
      // url parameters starts with ':'
      if (pathParts[z].indexOf(':') !== 0 && pathParts[z] !== currentPathParts[z]) {
        isValid = false;
        break;
      }
    }

    if (isValid) {
      currentPageType = (pageTypesData as unknown as Record<string, string | boolean>)[urls[i]];

      if (typeof currentPageType !== 'string') {
        return;
      }

      break;
    }
  }

  dataLayer.push({
    event: 'page_view',
    event_name: 'page_view',
    user_id: security.user,
    user_status: security.user ? 'Loggé' : 'Anonyme',
    page_type: currentPageType || 'Generic',
    user_email: customer ? customer.email : '-',
  });
}

export function handleSurveyPageLoaded(firstPage = false): void {
  dataLayer.push({
    user_id: security.user,
    user_status: security.user ? 'Loggé' : 'Anonyme',
    page_type: firstPage ? 'Survey' : 'Diagnosis',
  });
}

export function handleClickOnStartSurveyLink(): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: 'diagnostic_start_header',
  });
}

export function handleClickOnStartPrimesLink(): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: 'primes_start_header',
  });
}

export function handleSurveyQuestion(question: Question, responses: SurveyResponses, eventName = 'view_question', motivation = ''): void {
  let housing = '-';

  switch (responses[questionResource.ALIAS_QUESTION_DWELLING_TYPE]) {
    case 0: {
      housing = 'Maison';
      break;
    }
    case 1: {
      housing = 'Appartement';
      break;
    }
    default: {
      break;
    }
  }

  dataLayer.push({
    event: 'generic_event',
    event_name: eventName,
    chapter: question.group.label,
    housing,
    motivation: motivation || '-',
    question_id: question.alias,
    question_label: question.label,
  });
}

export function handleSurveyContinue(): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: 'finish',
  });
}

export function handleCollectiveHeaterForm(): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: 'view_collective_heater_form',
  });
}

export function handleSubmitCollectiveHeaterForm(data: ContactForm): void {
  dataLayer.push({
    event: 'form',
    event_name: 'submit_collective_heater_form',
    user_data: `${data.firstName}/${data.lastName}/${data.email}/${data.phone || '0000000000'}`,
  });
}

export function handleFirstTimeOnDiagnosticPage(survey: Survey, customer?: Customer): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: 'view_synthesis',
    user_data: customer ? `${customer.firstName}/${customer.lastName}/${customer.email}/${customer.phone || '0000000000'}` : '-',
    timer: ((survey.processingTaskFirstResultDuration || 0) / 60).toFixed(1),
  });
}

export function handleClickExpertCTA(): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: 'click_expert',
  });
}

export function handleClickContactCTA(category: string, label: string): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: 'click_contact_us',
    category,
    label,
  });
}

export function handleClickSeeMore(category: string): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: 'click_more',
    category,
  });
}

export function handleClickSeeEarnings(category: string): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: 'click_earnings',
    category,
  });
}

export function handleClickRenovPartner(category: string): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: 'click_guide',
    category,
  });
}

export function handleClickExportCTA(): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: 'click_save_result',
  });
}

export function handleClickHeaderReco(category: string): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: 'click_anchor',
    anchor: category,
  });
}

export function handleClickUserContact(name: string): void {
  dataLayer.push({
    event: 'generic_event',
    event_name: `click_contact_${name}`,
  });
}

export function handleSubmitUserContact(name: string, customer: Customer): void {
  dataLayer.push({
    event: 'form',
    event_name: `submit_contact_${name}`,
    user_data: `${customer.firstName}/${customer.lastName}/${customer.email}/${customer.phone || '0000000000'}`,
  });
}

export const navbarContactLinkListenerRaw = `
  function handleClickOnNavbarContackLink(e) {
    if (!window.google_tag_manager) {
      return;
    }

    e.preventDefault();

    dataLayer.push({
        'event': 'generic_event',
        'event_name': 'contact_header',
        'eventCallback': function() {
          window.location.href = String(e.currentTarget.getAttribute('href'));
        },
    });
  }

  document.querySelectorAll('a[data-layer="contact-link"]').forEach(el => {
    el.addEventListener('click', handleClickOnNavbarContackLink);
  });
`;
export function handleRegistrationStep(step: number): void {
  let stepAlias = '';

  switch (step) {
    case 4: {
      // Handled by handleRegistrationSucceed function
      return;
    }
    case 3: {
      stepAlias = 'view_third_step';
      break;
    }
    case 2: {
      stepAlias = 'view_second_step';
      break;
    }
    case 1:
    default: {
      stepAlias = 'view_first_step';
      break;
    }
  }

  dataLayer.push({
    event: 'generic_event',
    event_name: stepAlias,
  });
}

export function handleRegistrationSucceed(user: Customer): void {
  dataLayer.push({
    event: 'signup',
    event_name: 'form_signup',
    user_data: ['firstName', 'lastName', 'email', 'phone', 'source', 'dayDisponibility', 'hoursDisponibility']
      .map((prop) => (user as any)[prop]) // eslint-disable-line
      .join('/'),
    susbcription_origin: user.survey ? 'Diagnostic' : 'Header',
  });
}

export function handleRegistrationValidated(user: Customer): void {
  dataLayer.push({
    event: 'signup_validate',
    event_name: 'form_signup_validate',
    user_data: ['firstName', 'lastName', 'email', 'phone', 'source', 'dayDisponibility', 'hoursDisponibility']
      .map((prop) => (user as any)[prop]) // eslint-disable-line
      .join('/'),
    susbcription_origin: user.survey ? 'Diagnostic' : 'Header',
  });
}

// Événements : Simulateur de prime
interface CustomerEventData {
  housing: string;
  exactWork: string;
  amount: string;
  family?: string;
  revenues?: string;
  status?: string;
  projectName?: string;
  cumulation?: string;
  step?: string;
  chapter?: string;
  questionLabel?: string;
  workType?: string;
}

type OmitedCustomerEventData = Omit<CustomerEventData, 'exactWork' | 'amount'>;

type CustomerEventName = 'page_view' | 'start_form_incentive_simulation' | 'start_chapter_incentive_simulation' | 'complete_step_incentive_simulation' | 'complete_chapter_incentive_simulation' | 'complete_form_incentive_simulation' | 'view_synthesis' | 'synthesis' | 'click_bonus' | 'validate_project_info' | 'start_project' | 'click_current_file' | 'step_form_file' | 'complete_form_file' | 'no_pro';

interface DataLayerEvent {
  event: string;
  event_name: CustomerEventName;
  [key: string]: string;
}

function pushToDataLayer(event: DataLayerEvent): void {
  dataLayer.push(event);
}

function createCustomerAreaEvent(eventName: CustomerEventName, eventData: Record<string, string>): void {
  pushToDataLayer({
    event: 'customer area',
    event_name: eventName,
    ...eventData,
  });
}

function createSimulationEvent(eventName: CustomerEventName, eventData: Record<string, string>): void {
  pushToDataLayer({
    event: 'simulation',
    event_name: eventName,
    ...eventData,
  });
}

function createViewSynthesisEvent(eventName: CustomerEventName, eventData: Record<string, string>): void {
  pushToDataLayer({
    event: 'view_synthesis',
    event_name: eventName,
    ...eventData,
  });
}

function createSynthesisEvent(eventName: CustomerEventName, eventData: Record<string, string>): void {
  pushToDataLayer({
    event: 'synthesis',
    event_name: eventName,
    ...eventData,
  });
}

// View Page
export function handleViewPage(eventData: Record<string, string>): void {
  pushToDataLayer({
    event: 'page_view',
    event_name: 'page_view',
    user_id: eventData.userId,
    page_type: eventData.pageType,
    user_status: eventData.userStatus,
    user_email: eventData.userEmail,
    cible: eventData.cible,
    category: eventData.category,
    tag: eventData.tag,
  });
}

function simulationCommonAttributes(params: OmitedCustomerEventData): Record<string, string> {
  return {
    chapter: params.chapter ?? '',
    housing: params.housing,
    step: params.step ?? '',
    question_label: params.questionLabel ?? '',
    work_type: params.workType || '',
  };
}

// Simulation events
export function trackSimulationPrimeStart(): void {
  createSimulationEvent('start_form_incentive_simulation', {});
}

export function trackSimulationStartGroup(params: OmitedCustomerEventData): void {
  createSimulationEvent('start_chapter_incentive_simulation', {
    ...simulationCommonAttributes(params),
  });
}

export function trackSimulationEndStep(params: OmitedCustomerEventData): void {
  createSimulationEvent('complete_step_incentive_simulation', {
    ...simulationCommonAttributes(params),
  });
}

export function trackSimulationEndGroup(params: OmitedCustomerEventData): void {
  createSimulationEvent('complete_chapter_incentive_simulation', {
    ...simulationCommonAttributes(params),
  });
}

export function trackSimulationEnd({ housing, workType }: { housing: string; workType: string }): void {
  createSimulationEvent('complete_form_incentive_simulation', { housing, work_type: workType });
}

export function trackSimulationViewResult({ housing, exactWork, amount }: { housing: string; exactWork: string; amount: string }): void {
  createViewSynthesisEvent('view_synthesis', { housing, exact_work: exactWork, bonus_sonergia: amount });
}

export function trackSimulationResultBtnClick(
  params: CustomerEventData & {
    buttonId: string;
    buttonName: string;
  }
): void {
  createSynthesisEvent('click_bonus', { button_id: params.buttonId, button_name: params.buttonName, housing: params.housing, exact_work: params.exactWork, bonus_sonergia: params.amount });
}

// Customer area events

export function trackClickDemandPrime(
  params: CustomerEventData & {
    buttonId: string;
    buttonName: string;
  }
): void {
  createCustomerAreaEvent('click_bonus', { button_id: params.buttonId, button_name: params.buttonName, housing: params.housing, exact_work: params.exactWork, bonus_sonergia: params.amount });
}

function customerCommonAttributes(simulation: Resultat): Record<string, string> {
  return {
    housing: simulation?.logement?.typeLogement,
    exact_work: simulation?.travaux.operation || '',
    bonus_sonergia: simulation?.montants?.montantPrimeEnergie?.toString(),
    status: simulation?.profile?.status || '',
  };
}

export function trackClickValideInfo(simulation: Resultat, nbrPersonnes: string, selectedRevenue: string): void {
  createCustomerAreaEvent('validate_project_info', {
    ...customerCommonAttributes(simulation),
    family: nbrPersonnes?.toString() || '',
    revenues: selectedRevenue,
  });
}

export function trackClickStartProject(simulation: Resultat): void {
  createCustomerAreaEvent('start_project', {
    ...customerCommonAttributes(simulation),
    family: simulation?.profile?.numberOfPeople?.toString() || '',
    revenues: simulation?.profile?.categorie || '',
  });
}

export function trackClickCurrentProject(simulation: Resultat, cumulation: string): void {
  createCustomerAreaEvent('click_current_file', {
    ...customerCommonAttributes(simulation),
    project_name: simulation?.travaux.operation,
    exact_work: simulation?.travaux.operation || '',
    cumulation,
  });
}

export function trackClickFinishPerStep(simulation: Resultat, cumulation: string, step: string): void {
  createCustomerAreaEvent('step_form_file', {
    ...customerCommonAttributes(simulation),
    project_name: simulation?.travaux.operation,
    exact_work: simulation?.travaux.operation,
    cumulation,
    step,
  });
}

export function trackClickSendAH(simulation: Resultat, cumulation: string): void {
  createCustomerAreaEvent('complete_form_file', {
    ...customerCommonAttributes(simulation),
    project_name: simulation?.travaux.operation,
    exact_work: simulation?.travaux.operation,
    cumulation,
  });
}

export function trackClickNoPro(simulation: Resultat): void {
  createCustomerAreaEvent('no_pro', {
    ...customerCommonAttributes(simulation),
    family: simulation?.profile?.numberOfPeople?.toString() || '',
    revenues: simulation?.profile?.categorie || '',
  });
}
