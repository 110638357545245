import SimulationAPI from 'features/primes/simulation/questionnaire/services/simulationApi';
import { CHOIX_FORMULE } from 'utils/constants';
import type { Customer } from 'core';
import { clearSimulationFromLocalStorage } from 'features/dashboard/helpers/utils';
import { Answer, ISimulation, UpdateSimulation } from '../interfaces/questionnaire';

/**
 * Executes a simulation based on the user's answers.
 * @param userAnswersWithoutTravaux - An array of answers without operand.
 * @param userAnswersTravaux - An array of answers with operand.
 * @returns The simulation result.
 */
export const useSimulation = async (userAnswersWithoutTravaux: Answer[], userAnswersTravaux: Answer[], userAnswersBonification: Answer[], userAnswersMpr: Answer[], passedIdFormule?: string | null, user?: Customer | null, isEdit?: UpdateSimulation, partenaire?: string): Promise<ISimulation> => {
  const lastParcourId = userAnswersWithoutTravaux[userAnswersWithoutTravaux.length - 1]?.parcoursId;
  const [formuleId] = userAnswersTravaux.map((answer) => answer.formuleId);
  const formattedUserAnswersWithoutOperande = userAnswersWithoutTravaux.map((answer) => ({
    questionId: answer.questionId,
    value: answer.value,
    response: answer.response,
  }));

  const formattedUserAnswersOperande = userAnswersTravaux.map((answer) => ({
    parcoursId: answer.parcoursId,
    questionId: answer.questionId,
    value: answer.value,
    response: answer.response,
  }));

  const formattedUserAnswersBonification = userAnswersBonification.map((answer) => ({
    questionId: answer.questionId,
    value: answer.value,
  }));

  const formattedUserAnswersMpr = userAnswersMpr.map((answer) => ({
    questionId: answer.questionId,
    value: answer.value,
  }));

  const choixFormule = {
    parcoursId: CHOIX_FORMULE,
    value: passedIdFormule || formuleId,
    response: passedIdFormule ? [passedIdFormule] : [formuleId],
  };

  const fostQuestions = choixFormule.value ? [choixFormule, ...formattedUserAnswersOperande] : [...formattedUserAnswersOperande];

  const payload = {
    parcoursId: lastParcourId,
    questions: formattedUserAnswersWithoutOperande,
    questions_fost: fostQuestions || [],
    questions_bonifications: formattedUserAnswersBonification,
    questions_mpr: formattedUserAnswersMpr || [],
    userId: user ? user.id : null,
    partenaire,
  };

  let simulation;
  if (isEdit && isEdit.simulationId && isEdit.update) {
    simulation = await SimulationAPI.updateSimulation(isEdit.simulationId, payload);
  } else {
    simulation = await SimulationAPI.postSimulation(payload);
  }

  return simulation;
};

interface simulationFromLocalStorageInterface {
  userAnswersWithoutTravaux: Answer[];
  userAnswersTravaux: Answer[];
  userAnswersBonification: Answer[];
  partenaire: string;
  userAnswersMpr: Answer[];
}

const getSimulationFromLocalStorage = (): simulationFromLocalStorageInterface | null => {
  const userAnswersWithoutOperandeJSON = localStorage.getItem('userAnswersWithoutTravaux');
  const userAnswersOperandeJSON = localStorage.getItem('userAnswersTravaux');
  const userAnswersBonificationJSON = localStorage.getItem('userAnswersBonification');
  const partenaire = localStorage.getItem('partenaire');

  console.log('getSimulationFromLocalStorage', partenaire);
  const userAnswersMprJSON = localStorage.getItem('userAnswersMpr');

  if (userAnswersWithoutOperandeJSON === null && userAnswersOperandeJSON === null) {
    console.log('userAnswersWithoutTravaux and userAnswersTravaux are not set');
    return null;
  }

  if (userAnswersWithoutOperandeJSON === null) {
    console.log('userAnswersWithoutTravaux is not set');
    return null;
  }

  if (userAnswersOperandeJSON === null) {
    console.log('userAnswersTravaux is not set');
    return null;
  }

  if (userAnswersBonificationJSON === null) {
    console.log('userAnswersBonification is not set');
    return null;
  }

  if (userAnswersMprJSON === null) {
    console.log('userAnswersMpr is not set');
    return null;
  }

  const userAnswersWithoutOperandeDecode = JSON.parse(userAnswersWithoutOperandeJSON);
  const userAnswersOperandeDecode = JSON.parse(userAnswersOperandeJSON);
  const userAnswersBonificationDecode = JSON.parse(userAnswersBonificationJSON);
  const userAnswersMprDecode = JSON.parse(userAnswersMprJSON);

  if (!Array.isArray(userAnswersWithoutOperandeDecode)) {
    throw new Error('userAnswersWithoutOperandeDecode is not an array of Answer');
  }

  if (!Array.isArray(userAnswersOperandeDecode)) {
    throw new Error('userAnswersOperandeDecode is not an array of Answer');
  }

  if (!Array.isArray(userAnswersBonificationDecode)) {
    throw new Error('userAnswersBonificationDecode is not an array of Answer');
  }

  if (!Array.isArray(userAnswersMprDecode)) {
    throw new Error('userAnswersMprDecode is not an array of Answer');
  }

  return {
    userAnswersWithoutTravaux: userAnswersWithoutOperandeDecode,
    userAnswersTravaux: userAnswersOperandeDecode,
    partenaire: partenaire || '',
    userAnswersBonification: userAnswersBonificationDecode,
    userAnswersMpr: userAnswersMprDecode,
  };
};

/**
 * Executes a simulation based on the user's answers.
 * @returns The simulation result.
 * @param user
 */
export const useSimulationFromLocalStorage = async ({ user, isEdit }: { user?: Customer; isEdit?: UpdateSimulation } = {}): Promise<string | number | null> => {
  const formuleId = localStorage.getItem('formuleId');

  const simulationFromLocalStorage = getSimulationFromLocalStorage();

  if (simulationFromLocalStorage === null) {
    return null;
  }

  const { userAnswersWithoutTravaux, userAnswersTravaux, userAnswersBonification, partenaire, userAnswersMpr } = simulationFromLocalStorage;

  const { simulationId } = await useSimulation(userAnswersWithoutTravaux, userAnswersTravaux, userAnswersBonification, userAnswersMpr, formuleId, user, isEdit, partenaire);

  if (!simulationId) {
    throw new Error('Une erreur est survenue lors de la creation de la simulation');
  }

  clearSimulationFromLocalStorage();

  return simulationId;
};
