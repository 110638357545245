import { Montant } from 'features/primes/simulation/resultat/interfaces/resultat';
import React, { FC } from 'react';

interface PrimeContentBoxProps {
  isRemplassableCarRevision: boolean;
  isRemplassableCarDesactivation: boolean;
  isDetail?: boolean;
  isNonCumulable?: boolean;
  isClosed?: boolean;
  montants?: Montant;
  isDossierExpire?: boolean;
}

interface PrimeEstimatifTravauxProps {
  montants: Montant;
}

const PrimeEstimatifTravaux: React.FC<PrimeEstimatifTravauxProps> = ({ montants }) => (
  <div className="dashboard__demandes__list__item__prime__amount">
    <div className="montant-item">
      <span className="montant-label">Montant des travaux</span>
      <span className="montant-value">{montants.montantEstimatifTravaux} €</span>
    </div>
    <div className="montant-item">
      <span className="montant-label">Prime Énergie Sonergia *</span>
      <span className="montant-value">{montants.montantPrimeEnergie} €</span>
    </div>
    {montants.montantMpr ? (
      <div className="montant-item">
        <span className="montant-label">MaPrimeRénov&apos;</span>
        <span className="montant-value">{montants.montantMpr} €</span>
      </div>
    ) : null}
    <div className="montant-item montant-resteacharge">
      <span className="montant-label">Reste à charge</span>
      <span className="montant-value">{montants.resteACharge} €</span>
    </div>
  </div>
);

const PrimeContentBox: FC<PrimeContentBoxProps> = ({ isRemplassableCarRevision, isRemplassableCarDesactivation, isDetail, isNonCumulable, isClosed, montants, isDossierExpire }) => {
  const isRemplacableAndNotNonCumulable = (isRemplassableCarRevision || isRemplassableCarDesactivation) && !isNonCumulable;
  const isDisabled = isNonCumulable || isClosed || isRemplassableCarRevision || isRemplassableCarDesactivation || isDossierExpire;

  const isEstimatifTravauxAvailable = montants?.montantEstimatifTravaux !== null;

  const classNames = `dashboard__demandes__list__item__prime__amount ${isDetail ? 'details' : ''} ${isRemplacableAndNotNonCumulable ? 'left' : ''} ${isDisabled ? 'grised' : ''}`;

  const primaryText = isRemplacableAndNotNonCumulable ? 'Nouveau montant de prime' : 'Prime Énergie Sonergia *';
  const secondaryText = isRemplacableAndNotNonCumulable ? 'Mettez à jour vos informations pour découvrir votre nouvelle prime' : `${montants?.montantPrimeEnergie} €`;
  return (
    <div className={classNames}>
      {isRemplassableCarDesactivation || isDossierExpire ? (
        <>
          <p className="dashboard__demandes__list__item__prime__amount-title">Expiration de votre projet</p>
          <p className="dashboard__demandes__list__item__prime__amount-subtext">Cette simulation n’est plus éligible aux primes CEE.</p>
        </>
      ) : (
        <>
          {montants && isEstimatifTravauxAvailable && !isDetail ? (
            <PrimeEstimatifTravaux montants={montants} />
          ) : (
            <>
              <p className="dashboard__demandes__list__item__prime__amount-text">{primaryText}</p>
              <p className={`dashboard__demandes__list__item__prime__amount-${isRemplacableAndNotNonCumulable ? 'subtext' : 'value'}`}>{secondaryText}</p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PrimeContentBox;
