import { Question, questionResource } from '@concerto-home/core';

export interface Contrainte {
  minValue?: number;
  maxValue?: number;
  minLength?: number;
  maxLength?: number;
  oneChoiceOnly?: boolean;
  required?: boolean;
  hasVariableCondition?: boolean;
}
export interface ChoicesChoixFormule {
  id: number;
  formuleId: number;
  min: number;
  max: number;
  label?: string;
  value?: string;
}
export interface QuestionPrime extends Question {
  parcoursId: string;
  formuleId?: any;
  questionId: string;
  userAnswer: Answer;
  isStepProgression: boolean;
  shouldExit?: boolean;
  shouldWarn?: boolean;
  codeTravaux?: string;
  isTravaux?: boolean;
  isBonification?: boolean;
  contraintes?: Contrainte;
  help?: string;
  isCodeTravauxRequired?: boolean;
  responseUrl?: string;
  isLastQuestion?: boolean;
  emptyQuestion?: boolean;
  warning?: {
    id?: number;
    type?: string;
    picto?: string;
    title?: string;
    text?: string;
  };
  blocName?: string;
  errorLabel?: string;
  valid?: boolean;
  message?: {
    conditionMessage: string;
    message: string;
  };
  skip?: boolean;
  choicesChoixFormule?: ChoicesChoixFormule[];
  isChoiceAndNoResponse?: boolean;
  isMpr?: boolean;
}

export interface Answer {
  questionId?: string | number;
  value: string | number;
  parcoursId?: string;
  response: string[];
  isTravaux?: boolean;
  codeTravaux?: string;
  formuleId?: string;
  label?: string;
}

type IModeReponseType = {
  [key: string]: string;
};

export const ModeReponseType: IModeReponseType = {
  choix_unique: 'choice',
  saisie_adresse: questionResource.ALIAS_QUESTION_ADDRESS,
  saisie_numerique: 'float',
  saisie_decimal: 'float',
  saisie_date: 'date',
  saisie_texte: 'text',
};

export const ModeAffichageDisplay: IModeReponseType = {
  column: 'column',
  row: '',
  grid: 'grid',
};

export interface ISimulation {
  simulationId: string;
  simulationUUID: string;
}

export interface SimulationQuestion {
  questionId: string | number | undefined;
  value: string | number;
  response: string[];
}

export interface SimulationQuestionFost {
  parcoursId: string;
  value?: string;
  response: (string | undefined)[];
}

export interface Simulation {
  parcoursId?: string;
  questions: SimulationQuestion[];
  questions_fost: (SimulationQuestion | SimulationQuestionFost)[];
  userId?: number | string | null;
}

export interface EditSimulation {
  categorie: string;
  nombrePersonnes: number;
  isOccupant: boolean;
  adresse: string;
  foyers_fiscaux: Array<{ nom: any; prenom: any }>;
  telephone: string;
}

export interface UpdateSimulation {
  simulationId: string;
  update: boolean;
}

export type GroupedQuestions = Record<string, QuestionPrime[]>;
export type QuestionType = 'question' | 'operande' | 'bonification' | 'mpr';
